.footer {
  background-color: #343a40;
  color: white;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  height: 80px;
  width: auto;
  margin-right: 20px;
}

.footer-social-icons {
  display: flex;
  margin: 10px 0;
}

.footer-social-link {
  color: white;
  margin: 0 10px;
  font-size: 24px;
  text-decoration: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-social-link:hover {
  color: orange;
  transform: scale(1.1);
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-nav {
  display: flex;
  margin-bottom: 10px;
}

.footer-nav-link {
  color: white;
  margin: 0 10px;
  font-size: 16px;
  text-decoration: none;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-nav-link:hover {
  color: orange;
  transform: scale(1.1);
}

.footer-contact {
  color: white;
  transition: color 0.3s ease;
}

.footer-contact:hover {
  color: orange;
}

.footer-copyright {
  color: rgb(174, 174, 174);
  font-size: 80%; /* Reduce font size by 20% */
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    width: 100%;
    display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
  .footer-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
  }
  .footer-social-icons {
    justify-content: flex-end;
  }
  .footer-nav {
    flex-direction: row;
    align-items: center;
  }
}
