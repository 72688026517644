.buy-it-meow-section {
  text-align: center;
  padding: 0px;
  background-color: black;
  position: relative; /* Make the container relative for absolute positioning */
}

.buy-it-meow-text {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 24px;
  color: orange;
  font-family: 'Lilita One', sans-serif; /* Apply Lilita One font */
  letter-spacing: 0.0em;
}

.buy-it-meow-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  position: relative; /* Ensure buttons and text stay in place */
}

.cat-image {
  width: 256px;
  height: 256px;
  object-fit: contain;
  position: relative; /* Position relative for alignment */
  margin-left: 20px; /* Add margin to space out from buttons */
}

.buy-it-meow-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px; /* Add margin for spacing */
}

.buy-it-meow-button {
  height: 54.6px; /* Reduced height by 30% */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #fff; /* Solid white background */
  border-radius: 8px; /* Rounded corners */
  margin: 10px; /* Margin between buttons */
  transition: transform 0.2s ease; /* Transition for the scale effect */
  width: 70%; /* Reduced width by 30% */
  max-width: 140px; /* Max width for buttons */
  overflow: hidden; /* Ensure no overflow of content */
  position: relative; /* Position relative to contain the image */
  border: none; /* Remove any border */
}

.buy-it-meow-button img {
  height: auto; /* Maintain aspect ratio */
  width: 100%; /* Ensure the image fills the button */
  object-fit: contain; /* Contain the image within the button */
  display: block;
}

.buy-it-meow-button:focus {
  outline: none; /* Remove outline on focus */
}

.buy-it-meow-button:active {
  transform: scale(0.95); /* Scale down the button slightly on click */
}

.buy-it-meow-button:hover {
  transform: scale(1.05); /* Scale up the button slightly on hover */
}

/* Adjust layout for web screens */
@media (min-width: 769px) {
  .buy-it-meow-section {
    display: flex;
    flex-direction: column; /* Keep column direction */
    align-items: center;
    justify-content: center;
  }
  .buy-it-meow-content {
    display: flex;
    flex-direction: row; /* Change to row direction */
    align-items: flex-start;
    justify-content: center;
  }
  .buy-it-meow-buttons-container {
    margin-top: 20px; /* Add margin to space out from text */
  }
  .buy-it-meow-text {
    margin-right: 200px; /* Reset margin */
  }
  .cat-image {
    width: 200px; /* Adjust width for larger screens */
    height: 200px; /* Adjust height for larger screens */
    margin-left: 0; /* Remove extra margin */
  }
}

/* Responsive design for tablets and mobiles */
@media (max-width: 768px) {
  .buy-it-meow-content {
    display: flex;
    flex-direction: row; /* Change to row direction */
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
  .buy-it-meow-text {
    margin-right: 20px; /* Add margin to space out from cat image */
  }
  .cat-image {
    width: 128px; /* Adjust image size for smaller screens */
    height: 128px;
  }
}

@media (max-width: 576px) {
  .buy-it-meow-content {
    flex-direction: row; /* Maintain row direction for smaller screens */
    justify-content: center;
    align-items: center;
  }
  .buy-it-meow-button {
    height: 28px; /* Further adjust height for very small screens */
    max-width: 70px; /* Adjust max-width for very small screens */
    margin: 5px; /* Reduce gap between buttons */
  }
  .cat-image {
    width: 128px; /* Adjust image size for very small screens */
    height: 128px;
  }
}
