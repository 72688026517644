/* src/components/Screenshots/Screenshots.css */
.screenshots-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  padding: 20px;
  background-color: black;
}

.screenshot-item {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.screenshot-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.screenshot-item:hover img {
  transform: scale(1.05);
}

/* Media query for larger screens */
@media (min-width: 769px) {
  .screenshots-container {
    grid-template-columns: repeat(3, 1fr); /* 3 screenshots per row */
  }

  .screenshot-item img {
    width: 100%;
    height: auto;
  }
}
