.ratings-section {
  text-align: center;
  background-color: black; /* Set background color to black */
  padding: 20px;
}

.ratings-header {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center;
  margin-bottom: 20px;
}

.pegi-image {
  width: 64px; /* Smaller size for PEGI image */
  height: 64px;
  margin-right: 15px; /* Space between PEGI image and text-stars container */
}

.text-stars-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center align vertically within the container */
  align-items: flex-start; /* Align items to start horizontally */
}

.stars-container {
  display: flex;
  align-items: center;
}

.star-image {
  width: 25px;
  height: 25px;
  margin: 0 3px; /* Space between stars */
}

.text-container {
  display: flex;
  align-items: center; /* Align text vertically in the center */
  margin-top: 10px;
  
}

.top-rated-text {
  color: white;
  font-size: 1em;
  margin-right: 10px; /* Space to the right of the text */
}

.number-rated-text {
  color: white;
  font-size: 1em;
}

.ratings-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjusted for smaller screens */
  gap: 10px; /* Default gap */
  padding: 20px;
}

.rating-item {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rating-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.rating-item:hover img {
  transform: scale(1.05);
}

/* Media query for larger screens */
@media (min-width: 769px) {
  .ratings-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
    gap: 5px; /* Reduced gap for larger screens */
  }

  .rating-item {
    width: 100%; /* Ensure the items fill the grid */
    max-width: 270px; /* Control the maximum width (10% reduction from 300px) */
    margin: 0 auto; /* Center the items */
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .ratings-container {
    display: grid;
    grid-template-columns: 1fr; /* Single column for mobile */
    gap: 10px; /* Adjust gap as needed */
  }

  .rating-item {
    width: 90%; /* Reduce size for mobile by 10% */
    max-width: 90%; /* Ensure the items fill the grid */
    margin: 0 auto; /* Center the items */
  }
}

