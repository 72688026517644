.hero {
  background-color: black;
  background-image: url('../../assets/bg-test.jpg');
  background-size: cover; /* Default size */
  background-position: top; /* Snap background to the top */
  background-repeat: no-repeat; /* Do not repeat the background image */
  height: 100vh; /* Default height */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.hero-content {
  z-index: 2;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%; /* Added to make content take full width */
  
}

.hero-content h1 {
  font-weight: bold;
  text-transform: uppercase;
  color: orange;
  font-family: 'Lilita One', sans-serif;
  letter-spacing: 0.04em;
  font-size: 4vw;
}

.paw-image {
  width: 5vw;
  height: 5vw;
  object-fit: contain;
}

.hero-buttons {
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #fff; /* Ensures white background */
  border-radius: 8px;
  margin: 5px;
  transition: transform 0.2s ease;
  width: 30vw;
  max-width: 200px;
  overflow: hidden;
  border: none;
}

.hero-buttons img {
  height: auto;
  width: 100%;
  object-fit: contain;
}

.hero-buttons:focus {
  outline: none;
}

.hero-buttons:active {
  transform: scale(0.95);
}

.hero-buttons:hover {
  transform: scale(1.05);
}

/* Adjust for very small screens */
@media (max-width: 576px) {
  .hero {
    background-size: 200%;
    height: 60vh;
  }

  .hero-content {
    margin-top: 280px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 8vw;
  }

  .paw-image {
    width: 10vw;
    height: 10vw;
  }

  .hero-buttons {
    height: 10vw;
    width: 30vw;
  }
}

/* Adjust for small screens */
@media (min-width: 577px) and (max-width: 768px) {
  .hero {
    background-size: 200%;
    height: 80vh;
  }

  .hero-content {
    margin-top: 400px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 7vw;
  }

  .paw-image {
    width: 8vw;
    height: 8vw;
  }

  .hero-buttons {
    height: 10vw;
    width: 30vw;
  }
}

/* Adjust for medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero {
    background-size: 120%;
    height: 55vh;
  }

  .hero-content {
    margin-top: 350px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 5vw;
  }

  .paw-image {
    width: 6vw;
    height: 6vw;
  }

  .hero-buttons {
    height: 6vw;
    width: 35vw;
  }
}

/* Adjust for large screens */
@media (min-width: 1025px) {
  .hero {
    background-size: 100%;
    height: 10h;
  }

  .hero-content {
    margin-top: 600px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 3.5vw;
  }

  .paw-image {
    width: 3vw;
    height: 3vw;
  }

  .hero-buttons {
    height: 3vw;
    width: 70vw;
  }
  
}

/* Adjust for extra large screens */
@media (min-width: 1440px) {
  .hero {
    background-size: 100%;
    height: 60vh;
  }

  .hero-content {
    margin-top: 420px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 4vw;
  }

  .paw-image {
    width: 2.5vw;
    height: 2.5vw;
  }

  .hero-buttons {
    height: 5vw;
    width: 15vw;
  }
}

/* Adjust for extra large screens */
@media (min-width: 1441px) {
  .hero {
    background-size: 100%;
    height: 100vh;
  }

  .hero-content {
    margin-top: 820px; /* Adjust this value to move the content down */
  }

  .hero-content h1 {
    font-size: 4vw;
  }

  .paw-image {
    width: 2.5vw;
    height: 2.5vw;
  }

  .hero-buttons {
    height: 3vw;
    width: 15vw;
  }
}

/* Adjust for small screens (320px-425px) */
@media (min-width: 320px) and (max-width: 425px) {
  .hero {
    background-size: 200%;
    height: 50vh;
  }


  .hero-content {
    margin-top: 210px; /* Adjust this value to move the content down */
  }
  

  .hero-content h1 {
    font-size: 7vw;
  }

  .paw-image {
    width: 12vw;
    height: 12vw;
  }

  .hero-buttons {
    height: 12vw;
    width: 40vw;
  }
}



