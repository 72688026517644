.header {
  background-color: transparent; /* Set background to transparent */
  position: absolute; /* Make the header position absolute */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.logo img {
  height: 80px; /* 50% larger than the previous 40px */
  width: auto;  /* Maintain aspect ratio */
}

.header .social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, transform 0.3s ease; /* Add transition for scaling and color */
}

.header .social-icons a:hover {
  color: orange; /* Change color to orange on hover */
}

.header .social-icons a:hover i {
  color: orange; /* Change color to orange on hover */
  transform: scale(1.1); /* Scale up the icon on hover */
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .social-icons {
    margin-top: 10px;
  }
}
