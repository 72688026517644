/* src/components/VideoDescription/VideoDescription.css */
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');


.video-text {
  font-size: 1.1em;
  color: white;
  margin-top: 20px; /* Adjusted margin for spacing */
  font-family: 'Roboto', sans-serif; /* Apply Lilita One font */
  font-weight:100; /* Ensure the text is regular */
}

.bold-text {
  font-weight:900; /* Make "I Am Cat" bold */
  color: orange;
}

.video-container {
  position: relative;
  width: 100%;
  margin: 0 auto; /* Center the video */
  padding-top: 56.25%; /* 16:9 aspect ratio */
  border-radius: 15px; /* Rounded corners for video container */
  overflow: hidden; /* Ensure rounded corners are applied to iframe */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

/* Media query for larger screens */
@media (min-width: 769px) {
  .video-description {
    padding: 10px 15px; /* Reduced padding for larger screens */
  }

  
  .video-text {
    font-size: 1.2em; /* Smaller font size for larger screens */
  }
}
